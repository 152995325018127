import { MockData } from "../components/Cards/Cards.models";

const carouselData: MockData[] = [
  {
    title: 'Connect with Like-Minded Jain Singles',
    content: `Find your ideal Jain partner through our dedicated platform. We bring together individuals who share your values, traditions, and aspirations. Explore profiles of like-minded singles and start meaningful conversations today. Join our community to find a partner who truly understands and respects your Jain way of life.`
  },
  {
    title: 'Secure and Trusted Matches',
    content: `At Jain Matrimony, we ensure that every profile is verified and genuine. Our commitment to security and authenticity means you can confidently connect with potential partners who are serious about finding a life partner. Experience a trusted matchmaking process designed with your safety and privacy in mind.`
  },
  {
    title: 'Personalized Matchmaking Experience',
    content: `Enjoy a personalized approach to finding your perfect match. Our platform uses advanced algorithms to provide recommendations based on your preferences and values. From detailed profiles to tailored suggestions, Jain Matrimony offers a unique and customized experience to help you find your ideal partner.`
  },
];


  export default carouselData