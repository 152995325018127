import { MockData } from "../components/Cards/Cards.models";
import Image from '../assets/Images/image.jpeg'
import Image2 from '../assets/Images/image2.jpeg'
import Image3 from '../assets/Images/image3.jpeg'
import Image4 from '../assets/Images/image4.jpeg'


const servicesData: MockData[] = [
    {
        image:Image,
      title: 'Welcome To TravoBuddy',
      content: `Welcome to TravoBuddy, your go-to carpooling app designed to make your daily commute smarter, greener, and more economical. Whether you're traveling across town or planning a longer journey, TravoBuddy connects you with fellow travelers heading in the same direction. Enjoy the benefits of reduced travel costs, minimized carbon footprint, and the opportunity to meet new people in your community. With user-friendly features and a commitment to safety and sustainability, TravoBuddy is your trusted companion for eco-friendly travel solutions. Join us today and start making a positive impact, one ride at a time.`
    },
    {
        image:Image2,
      title: 'Creating an account',
      content: 'Creating an account ensures you enjoy a seamless experience on TravoBuddy. Register now to unlock exclusive features, personalize your journey, and easily manage your rides. Join today for a smoother, more convenient way to travel!'
    },
    {
        image:Image3,
        title: 'Post Ride with TravoBuddy',
        content: `Turn your empty seats into opportunities with TravoBuddy's 'Post Ride' feature. Share your travel plans effortlessly and earn extra income by offering rides to fellow travelers. Whether you're heading across town or embarking on a longer journey, simply input your route, departure time, and pricing. Connect with passengers looking for safe, affordable travel options and enjoy the journey together. Join TravoBuddy today and start sharing rides while earning money in a community-focused, eco-friendly way.`
    },
    {
        image:Image4,
        title: 'Search Ride with TravoBuddy',
        content: `Explore seamless travel planning with TravoBuddy's advanced 'Search Ride' feature. Whether you're commuting daily or planning a weekend getaway, simply input your destination and preferred travel dates. Customize your search further by selecting ride preferences such as car type, departure time, and pricing options. Discover a variety of convenient travel choices, from quick local trips to longer journeys, all tailored to fit your schedule and preferences. With TravoBuddy, finding the perfect ride is easier than ever.`
    },
    // Add more mock data objects as needed
  ];

  export default servicesData