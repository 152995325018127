/* eslint-disable @typescript-eslint/naming-convention */
export enum EIsregister {
    ISREGISTER = 'ISREGISTER',
}

interface IPersonalDetails {
    birthPlace: string;
    birthTime: string;
    colour: string;
    createdOn: string;
    dateOfBirth: string;
    firstName: string;
    gender: string;
    height: string;
    lastName: string;
    maritalStatus: string;
    mobileNo: string;
    motherTongue: string | null;
    per_Id: number;
    physicalStatus: string;
    preferredPartnerGender: string | null;
    updatedOn: string;
    readyToMarryWithCast?:boolean;
    weight: string;
}

interface IProfessionalDetails {
    annualIncome: string;
    createdOn: string;
    educationDetails: string;
    employedIn: string;
    occupation: string;
    organization: string;
    professionalId: number;
    updatedOn: string;
}

interface IUserCredentials {
    createdOn: string;
    credId: number;
    newUserId: number | null;
    password: string;
    updatedOn: string;
    userName: string;
}

interface IUserData {
        createdOn: string;
        personalDetails: IPersonalDetails;
        primaryMobNumber: string;
        professionalDetails: IProfessionalDetails;
        updatedOn: string;
        userCred: IUserCredentials;
        custId: number;
}





export interface IMobileRegisterApiResponse {
    data: IUserData;
    status: string;
    message: string;
    responseCode: number;
}

export interface IRegisterMobileState {
    isregisterLoading: boolean;
    isregisterSuccess: IMobileRegisterApiResponse | null;
    isregisterError: string | null;
}