import React from 'react';
import "./PrivacyPolicyStyle.css"

const PrivacyPolicy = () => {
  return (
    <div className='privacyPolicy' style={{color:"black"}}>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy ("Privacy Policy”) applies to Jain Utkarsh Mandal’s website accessible at 
        <a href="https://utkarshvivah.com/" target="_blank" rel="noopener noreferrer">https://utkarshvivah.com/</a> 
        ("Website”). Collectively, these are referred to as the ("Site”), all owned and operated by 
        Vipracube Tech Solutions. ("Vipracube Tech Solutions”, "we”, or "us”). This Privacy 
        Policy describes how   Vipracube Tech Solutions collects, uses, and protects personal information collected through the 
        Site. It also describes the choices available to you regarding the use of, your access to, and how to update and 
        correct your personal information.
      </p>

      <h2>1. Personal Data We Collect</h2>

      <h3>Data You Provide:</h3>
      <p>
        When you register for an account, purchase a subscription, or participate in interactive features on our Site, 
        you provide us with personal data. This includes:
        <ul>
          <li><strong>Account Creation:</strong> First name, last name, email address.</li>
          <li><strong>Payment Processing:</strong> Payment and billing information, including credit card details 
              (processed through third-party payment processors).</li>
          <li><strong>Interactions:</strong> Information shared through feedback forms, discussions, and customer support.</li>
        </ul>
      </p>

      <h3>Data Collected Automatically:</h3>
      <p>
        We collect data automatically through your use of our Site, including:
        <ul>
          <li><strong>Technical Information:</strong> IP address, browser type, operating system, device identifiers.</li>
          <li><strong>Usage Information:</strong> Pages visited, time spent on pages, interaction details (e.g., clicks, scrolling).</li>
          <li><strong>Analytics:</strong> Data on user activity to improve our services.</li>
        </ul>
      </p>

      <h3>Data from Third Parties:</h3>
      <p>
        We may receive personal data from third-party sources such as:
        <ul>
          <li><strong>Business Partners:</strong> Information shared through collaborations and integrations.</li>
          <li><strong>Advertising Networks:</strong> Data to help us tailor marketing efforts.</li>
        </ul>
      </p>

      <h2>2. How We Use Your Personal Data</h2>
      <p>
        <strong>Provision of Services:</strong> We use personal data to provide, maintain, and improve our services, including:
        <ul>
          <li><strong>Account Management:</strong> Administration of user accounts and subscriptions.</li>
          <li><strong>Support:</strong> Responding to customer inquiries and resolving issues.</li>
        </ul>
      </p>

      <p>
        <strong>Enhancement and Personalization:</strong> We use data to enhance user experience and personalize content, including:
        <ul>
          <li><strong>Service Improvement:</strong> Updating our offerings and features based on user feedback and usage data.</li>
          <li><strong>Personalized Recommendations:</strong> Customizing content based on user activity and preferences.</li>
        </ul>
      </p>

      <p>
        <strong>Communication:</strong> We use personal data to communicate with you about:
        <ul>
          <li><strong>Service Updates:</strong> Notifications about changes to our services or policies.</li>
          <li><strong>Marketing:</strong> Sending promotional materials and updates (with your consent).</li>
        </ul>
      </p>

      <p>
        <strong>Safety and Security:</strong> We use data to ensure the safety and security of our Site and users, including:
        <ul>
          <li><strong>Monitoring:</strong> Detecting and preventing malicious activities or policy violations.</li>
          <li><strong>Compliance:</strong> Ensuring adherence to legal and regulatory requirements.</li>
        </ul>
      </p>

      <h2>3. Sharing of Personal Data</h2>
      <p>
        <strong>With Affiliates:</strong> We may share personal data with Jain Utkarsh Mandal affiliates for the purposes outlined in this Privacy Policy.
      </p>
      <p>
        <strong>With Service Providers:</strong> We may engage third-party vendors to perform services on our behalf, such as:
        <ul>
          <li><strong>Payment Processors:</strong> Handling transactions securely.</li>
          <li><strong>Marketing Partners:</strong> Delivering targeted advertising and promotional content.</li>
        </ul>
      </p>
      <p>
        <strong>For Legal Reasons:</strong> We may disclose personal data to comply with legal obligations, protect our rights, or respond to lawful requests.
      </p>

      <h2>4. Your Choices and Rights</h2>
      <p>
        <strong>Access and Correction:</strong> You have the right to access, update, or correct your personal data. You can do so through your account settings or by contacting us directly.
      </p>
      <p>
        <strong>Opt-Out:</strong> You can opt out of receiving marketing communications by following the unsubscribe instructions in those messages or adjusting your preferences in your account settings.
      </p>
      <p>
        <strong>Data Deletion:</strong> You can request the deletion of your personal data by contacting us. Note that certain data may be retained as required by law.
      </p>

      <h2>5. Security</h2>
      <p>
        We implement reasonable measures to protect your personal data from unauthorized access, use, or disclosure. However, no security system is impenetrable, and we cannot guarantee absolute security.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on our Website, and your continued use of the Site will constitute acceptance of the updated Privacy Policy.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <p>
        <strong>Jain Utkarsh Mandal, LLC</strong><br />
        Email: <a href="mailto:utkarshvivah@gmail.com">utkarshvivah@gmail.com</a><br />
        Address: Jain Utkarsh Mandal ,Dignmbar Jain Boarding,Near Modern Highschool, Kolhapur Road, Ichalkaranji, Tal. - Hathkangale, Dist.- Kolhapur
      </p>
    </div>
  );
};

export default PrivacyPolicy;
