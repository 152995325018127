

export enum ECardsDetails {
    GET_CARDS_DETAILS = 'GET_CARDS_DETAILS'
}



interface FamilyDetails {
    family_Id: number;
    fathersName: string;
    fathersMobileNo: string;
    fathersOccupation: string;
    familyMembers: string;
    noOfBrothers: number;
    noOfSisters: number;
    brothersMarried: number;
    sistersMarried: number;
    houseOwnership: string;
    maternalUnclesName: string;
    createdOn: string;
    updatedOn: string;
  }
  
  interface AddressDetails {
    addr_Id: number;
    addr: string;
    locality: string;
    pincode: string;
    country: string | null;
    state: string;
    city: string;
    createdOn: string;
    updatedOn: string;
  }
  
  interface CustomerCred {
    credId: number;
    userName: string;
    password: string;
    newCustId: number | null;
    createdOn: string;
    updatedOn: string;
  }
  
  interface ProfilePic {
    docId: number;
    profilePicUrl: string;
    bucketName: string;
    fileName: string;
    createdOn: string;
    updatedOn: string;
  }
  
  interface Customer {
    custId: number;
    registrationNo: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    primaryMobNumber: string;
    birthTime: string;
    birthPlace: string;
    motherTongue: string;
    maritalStatus: string;
    physicalStatus: string;
    height: string;
    hobby: string;
    colourOfBody: string;
    spec: string;
    bloodGroup: string;
    educationDetails: string;
    employedIn: string;
    occupation: string;
    organization: string;
    annualIncome: string;
    caste: string;
    subcaste: string;
    readyToMarryWithinSubcast: boolean;
    profilePicUrl: string | null;
    status: string;
    createdOn: string;
    updatedOn: string;
    familyDetails: FamilyDetails;
    addressDetails: AddressDetails[];
    customerCred: CustomerCred | null;
    profilePic: ProfilePic | null;
  }
  
  export interface Data {
    content: Customer[];
   pageSize: number,
   pageNumber: number,
   totalElements: number,
   totalPages: number,
   lastPage: false
  }
  

export interface IUserApiResponse {
    data: Data;
    status: string;
    message: string;
    responsecode: number;
}

export interface ICardState {
    CardSuccess: IUserApiResponse | null;
    CardLoading: boolean;
    CardError: string | null;
}