import React from 'react'
import "./TermsAndConditionStyle.css"
import { Link } from 'react-router-dom';

const TermsAndCondition = () => {
  return (
    <div className='termsAndConditionMain'>
      <div className='termsAndCondition'>
        <div className='headingContainerTC'>
            <h2 className='headingTC'>Terms And Conditions</h2>
        </div>
        <div className='headingContentTC'>
            <p>This site "JAIN UTKARSH MANDAL" is owned and managed by Vipracube Tech Solutions. By using our matrimonial services, you agree to the following terms and conditions.</p>
        </div>
        <ol className='mainContentTC'>
            <li className='listMainTC'>Registration:
                <ul>
                    <li className='listContentTC'>You must provide accurate and truthful information while registering your profile on our platform. Falsification of details may result in the suspension or termination of your account.</li>
                </ul>
            </li>
            <li className='listMainTC'>Membership and Payment:
                <ul>
                    <li className='listContentTC'>Certain features of our services may require you to purchase a paid membership. All payments must be made in advance using our designated payment methods.</li>
                </ul>
            </li>
            <li className='listMainTC'>Profile Screening:
                <ul>
                    <li className='listContentTC'>We reserve the right to screen or remove any profiles that violate our terms or fail to meet the authenticity criteria.</li>
                </ul>
            </li>
            <li className='listMainTC'>Privacy:
                <ul>
                    <li className='listContentTC'>Your personal information will be used in accordance with our <Link to="/PrivacyPolicy">Privacy Policy</Link>. We ensure that your data is handled securely, but it is your responsibility to safeguard your credentials.</li>
                </ul>
            </li>
            <li className='listMainTC'>Communication:
                <ul>
                    <li className='listContentTC'>Members are expected to communicate in a respectful and lawful manner. Any misuse of the platform or harassment will not be tolerated.</li>
                </ul>
            </li>
            <li className='listMainTC'>Cancellation:
                <ul>
                    <li className='listContentTC'>Cancellation of memberships and services must be done within the specified time frame. Please refer to our cancellation policies for detailed information.</li>
                </ul>
            </li>
            <li className='listMainTC'>Termination:
                <ul>
                    <li className='listContentTC'>We reserve the right to terminate your membership if any inappropriate behavior or violation of these terms is found.</li>
                </ul>
            </li>
            <li className='listMainTC'>Liability:
                <ul>
                    <li className='listContentTC'>While we aim to provide the best possible service, we are not responsible for any misuse of information or failed communications between members.</li>
                </ul>
            </li>
        </ol>
        <div className='lastUpdated'>
            <p>These terms and conditions are subject to change without notice. It is your responsibility to review them regularly.</p>
            <p>Last updated: September 9, 2024</p>
        </div>
        <ul>
            <li className='listMainTC'>Refund Policies:
                <ul>
                    <li className='listContentTC'>We do not offer refunds once a membership plan is purchased, except in exceptional circumstances outlined in our refund policy.</li>
                </ul>
            </li>
        </ul>
      </div>
    </div>
  )
}

export default TermsAndCondition;
