import React from 'react';
import image6 from '../../assets/Images/img5.jpeg';
import image7 from '../../assets/Images/img6.jpeg';
import './ImageCarouselStyle.css';


  

const ImageCarousel2 = () => {
  return (
    <div className="imageCarouselMain" >
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={image6}  style={{ borderRadius: '15px' ,width:"100%", height:"80%"}} alt="Carousel 1" />
          </div>
          <div className="carousel-item">
            <img src={image7}  style={{ borderRadius: '15px',width:"100%", height:"80%" }} alt="Carousel 2" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default ImageCarousel2;
