export enum EEdu {
    GET_EDUCATION = 'GET_EDUCATION'
}



// export interface IWishList {
//    newUserId?: string,
//    name?:string,
//    education?:string,
//    height?:string,
//    city?:string,
//    picUrl?:string,
//    user?: {
//        userId?: 1
//     }
// }




export interface IEDUApiResponse {
    data: null;
    status: string;
    message: string;
    responsecode: number;
}

export interface IEduState {
    UserSuccess: IEDUApiResponse | null;
    UserLoading: boolean;
    UserError: string | null;
}