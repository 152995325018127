export enum EStates {
    GET_STATE = 'GET_STATE'
}



// export interface IWishList {
//    newUserId?: string,
//    name?:string,
//    education?:string,
//    height?:string,
//    city?:string,
//    picUrl?:string,
//    user?: {
//        userId?: 1
//     }
// }




export interface IStatesApiResponse {
    data: null;
    status: string;
    message: string;
    responsecode: number;
}

export interface IState {
    UserSuccess: IStatesApiResponse | null;
    UserLoading: boolean;
    UserError: string | null;
}