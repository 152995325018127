import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EStates, IState, IStatesApiResponse } from "./State.models";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";



const userInitialState: IState = {
    UserLoading: false,
    UserSuccess: null,
    UserError: '',
};


export const getStateData = createAsyncThunk(EStates.GET_STATE, async () => {
    try {
        const tokanData = {
            username:"jainmatrimonyapi",
            password:"jainmatrimonyapi"
         }
         const Tokan = await axios.post(`${APIKEYS.API}verify`, tokanData)
         const token =Tokan.data.token;
         console.log('tokenn',token)
        if (token) {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
console.log('${APIKEYS.API}staticstates',`${APIKEYS.API}staticstates`)
            const response = await axios.get<IStatesApiResponse>(`${APIKEYS.API}staticstates`, {headers: headers});
            if(response) {
                console.log('reesśof state',response.data.data)
                return response.data;
            }
        }
    } catch (e) {
        console.error('Error fetching custome:', e);
        throw e;
    }
});

const getStateDataSlice = createSlice({
    name: EStates.GET_STATE,
    initialState: userInitialState,
    reducers: {
        resetData: (state) => {
            state.UserSuccess = null;
            state.UserError = null;
            state.UserLoading = false;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getStateData.pending, (state) => {
                state.UserLoading = true;
            })
            .addCase(getStateData.fulfilled, (state, action) => {
                state.UserLoading = false;
                if (action.payload) {
                    state.UserSuccess = action.payload;
                }
                state.UserError = null;
            })
           .addCase(getStateData.rejected, (state) =>{
            state.UserError = 'something went wrong';
            state.UserLoading = false;
           });
    },
});

export const { resetData } = getStateDataSlice.actions;
export default getStateDataSlice.reducer;