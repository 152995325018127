import { FormField } from "./ReusableModels";

export  const fields: FormField[] = [
    {
      type: 'text',
      name: 'Firstname',
      label: 'First Name*',
      placeholder: ' तुमचे पहिले नाव प्रविष्ट करा',
      section: 'Personal Details',
      onFocus: 'input1',
      registerProps: { required: 'firstname is required' }
    },
    {
      type: 'text',
      name: 'Middle name',
      label: 'Middle Name*',
      placeholder: 'तुमचे मध्य नाव प्रविष्ट करा',
      section: 'Personal Details',
      onFocus: 'input2',
      registerProps: { required: 'Middle name is required' }
    },
    {
        type: 'text',
        name: 'Lastname',
        label: 'Last Name*',
        section: 'Personal Details',
        placeholder: 'तुमचे आडनाव प्रविष्ट करा',
        onFocus: 'input3',
        registerProps: { required: 'firstname is required' }
      },
  
      {
        type: 'text',
        name: 'Mobile number',
        label: 'Mobile Number*',
        onFocus: 'input4',
        section: 'Personal Details',
        placeholder: 'तुमचा मोबाइल नंबर प्रविष्ट करा',
        registerProps: { required: 'Mobile number is required' }
      },
     

      // {
      //   type: 'text',
      //   name: 'User Name',
      //   label: 'User Name*',
      //   placeholder: 'Enter your user Name',
      //   section: 'Personal Details',
      //   registerProps: { required: 'user Name is required' }
      // },
      // {
      //   type: 'text',
      //   name: 'Password',
      //   label: 'Password*',
      //   placeholder: 'Enter your Password',
      //   section: 'Personal Details',
      //   registerProps: { required: 'Password is required' }
      // },
    {
      type: 'select',
      name: 'Gender',
      label: 'Gender',
      section: 'Personal Details',
      registerProps: { required: 'firstname is required' },
      options: [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
      ],
      isMulti: false
    },
    {
      type: 'datetime',
      name: 'Date Of Birth',
      label: 'Date Of Birth',
      section: 'Personal Details',
      registerProps: { required: 'Date Of Birth is required' },
      placeholder: 'तुमचा जन्मतारीख प्रविष्ट करा',

    },

    {
      type: 'Time',
      name: 'Time',
      label: 'Time',
      registerProps: { required: 'Time is required' },
      section: 'Personal Details',
      placeholder: 'तुमचा जन्म वेळ प्रविष्ट करा',
    },
    {
      type: 'text',
      name: 'Birth Place',
      label: 'Birth Place*',
      placeholder: 'तुमचा जन्मस्थान प्रविष्ट करा',
      section: 'Personal Details',
      registerProps: { required: 'Birth Place is required' }
    },

   
    {
      type: 'text',
      name: 'Hobbies',
      label: 'Hobbies',
      section: 'Personal Details',
      placeholder: 'तुमच्या छंदांचा प्रवेश करा',
      registerProps: { required: 'Hobbies is required' }
    },
    {
      type: 'text',
      name: 'Agricultural Land',
      label: 'Agricultural Land',
      placeholder: 'कृपया प्रविष्ट करा शेतजमीन',
      section: 'Personal Details',
      // registerProps: { required: 'Agricultural Land is required' }
    },
  
    
     
      {
        type: 'text',
        name: 'Expectation',
        label: 'Expectation',
        section: 'Personal Details',
        placeholder: 'तुमची अपेक्षा प्रविष्ट करा',
        registerProps: { required: 'Hobbies is required' }
      },

      {
        type: 'select',
        name: 'Physical Status',
        label: 'Physical Status',
        section: 'Personal Details',
        registerProps: { required: 'Physical Status is required' },
        options: [
          { value: 'Normal', label: 'Normal' },
          { value: 'Disabled', label: 'Disabled' }
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Colour',
        label: 'Colour',
        section: 'Personal Details',
        registerProps: { required: 'Colour is required' },
        options: [
          { value: 'Fair', label: 'Fair' },
          { value: 'Wheatish', label: 'Wheatish' },
          { value: 'Brown', label: 'Brown' },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Height',
        label: 'Height',
        registerProps: { required: 'Height is required' },
        section: 'Personal Details',
        options: [
          { value: "4ft", label: "4ft" },
          { value: "4ft 1 inch", label: "4ft 1 inch" },
          { value: "4ft 2 inch", label: "4ft 2 inch" },
          { value: "4ft 3 inch", label: "4ft 3 inch" },
          { value: "4ft 4 inch", label: "4ft 4 inch" },
          { value: "4ft 5 inch", label: "4ft 5 inch" },
          { value: "4ft 6 inch", label: "4ft 6 inch" },
          { value: "4ft 7 inch", label: "4ft 7 inch" },
          { value: "4ft 8 inch", label: "4ft 8 inch" },
          { value: "4ft 9 inch", label: "4ft 9 inch" },
          { value: "4ft 10 inch", label: "4ft 10 inch" },
          { value: "4ft 11 inch", label: "4ft 11 inch" },
          { value: "5ft", label: "5ft" },
          { value: "5ft 1 inch", label: "5ft 1 inch" },
          { value: "5ft 2 inch", label: "5ft 2 inch" },
          { value: "5ft 3 inch", label: "5ft 3 inch" },
          { value: "5ft 4 inch", label: "5ft 4 inch" },
          { value: "5ft 5 inch", label: "5ft 5 inch" },
          { value: "5ft 6 inch", label: "5ft 6 inch" },
          { value: "5ft 7 inch", label: "5ft 7 inch" },
          { value: "5ft 8 inch", label: "5ft 8 inch" },
          { value: "5ft 9 inch", label: "5ft 9 inch" },
          { value: "5ft 10 inch", label: "5ft 10 inch" },
          { value: "5ft 11 inch", label: "5ft 11 inch" },
          { value: "6ft", label: "6ft" },
          { value: "6ft 1 inch", label: "6ft 1 inch" },
          { value: "6ft 2 inch", label: "6ft 2 inch" },
          { value: "6ft 3 inch", label: "6ft 3 inch" },
          { value: "6ft 4 inch", label: "6ft 4 inch" },
          { value: "6ft 5 inch", label: "6ft 5 inch" },
          { value: "6ft 6 inch", label: "6ft 6 inch" },
          { value: "6ft 7 inch", label: "6ft 7 inch" },
          { value: "6ft 8 inch", label: "6ft 8 inch" },
          { value: "6ft 9 inch", label: "6ft 9 inch" },
          { value: "6ft 10 inch", label: "6ft 10 inch" },
          { value: "6ft 11 inch", label: "6ft 11 inch" },
          { value: "7ft", label: "7ft" },
          { value: "7ft 1 inch", label: "7ft 1 inch" },
          { value: "7ft 2 inch", label: "7ft 2 inch" },
          { value: "7ft 3 inch", label: "7ft 3 inch" },
          { value: "7ft 4 inch", label: "7ft 4 inch" },
          { value: "7ft 5 inch", label: "7ft 5 inch" },
          { value: "7ft 6 inch", label: "7ft 6 inch" },
          { value: "7ft 7 inch", label: "7ft 7 inch" },
          { value: "7ft 8 inch", label: "7ft 8 inch" },
          { value: "7ft 9 inch", label: "7ft 9 inch" },
          { value: "7ft 10 inch", label: "7ft 10 inch" },
          { value: "7ft 11 inch", label: "7ft 11 inch" },
        ],
        isMulti: false
      },

      {
        type: 'select',
        name: 'Spec',
        label:  'Spec',
        registerProps: { required: 'Spec is required' },
        section: 'Personal Details',
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Blood Group',
        registerProps: { required: 'Blood Group is required' },
        section: 'Personal Details',
        label:  'Blood Group',
        options: [
          { value: "O+", label: "O+" },
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "AB+", label: "AB+" },
    { value: "O-", label: "O-" },
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "AB-", label: "AB-" },
    { value: "Don't Know", label:"Don't Know" },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Maritial Status',
        label: 'Maritial Status',
        registerProps: { required: 'Maritial Status is required' },
        section: 'Personal Details',
        options: [
          {
            value:'Single',
            label: 'Single',
        },
        {
            value:'Widowed',
            label: 'Widowed',
        },
        {
            value:'divorced',
            label: 'divorced',
        },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Mother Toungue',
        registerProps: { required: 'Mother Toungue is required' },
        section: 'Personal Details',
        label:  'Mother Toungue',
        options: [
          { value: "Marathi", label: "Marathi" },
  { value: "English", label: "English" },
  { value: "Telugu", label: "Telugu" },
  { value: "Hindi", label: "Hindi" }, 
  { value: "Tamil", label: "Tamil" },
  { value: "Urdu", label: "Urdu" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Odia", label: "Odia" },
  { value: "Punjabi", label: "Punjabi" },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Cast',
        registerProps: { required: 'Cast  is required' },
        section: 'Personal Details',
        label:  'Cast',
        options: [
          {
            value: 'Any',
            label: 'Any',
          },
          {
            value: 'Digambar',
            label: 'Digambar',
          },
          {
            value: 'Shwetamber',
            label: 'Shwetamber',
          },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'Sub-cast',
        label:  'Sub-cast',
        registerProps: { required: 'Sub-cast  is required' },
        section: 'Personal Details',
        options: [
        {
            value:"Chaturth",
            label:"Chaturth",
        },
        {
            value:"Pancham",
            label:"Pancham",
        },
        {
            value:"Shetwal",
            label:"Shetwal",
        },
        {
            value:"Kasar",
            label:"Kasar",
        },
        {
            value:"Dashahumad",
            label:"Dashahumad",
        },
        {
            value:"Others",
            label:"Others",
        },
        ],
        isMulti: false
      },
      {
        type: 'select',
        name: 'House',
        label: 'House',
        registerProps: { required: 'House is required' },
        section: 'Personal Details',
        options: [
          { value: 'Own', label: 'Own' },
          { value: 'Rented', label: 'Rented' }
        ],
        isMulti: false
      },
      {
        type: 'select',
        section: 'Personal Details',
        name: 'Ready To Marry subcast',
        registerProps: { required: 'field is required' },
        label: 'Ready To Marry subcast',
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        isMulti: false
      },
      // {
      //   type: 'select',
      //   name: 'Education',
      //   label:  'Education',
      //   registerProps: { required: 'Education is required' },
      //   section: 'Professional Details',
      //   options: 
      //     [
      //       { value: '12th', label: '12th' },
      //       { value: 'B.A.', label: 'B.A.' },
      //       { value: 'B.Com', label: 'B.Com' },
      //       { value: 'M.Com', label: 'M.Com' },
      //       { value: 'B.Sc', label: 'B.Sc' },
      //       { value: 'M.Sc', label: 'M.Sc' },
      //       { value: 'BE', label: 'BE' },
      //       { value: 'B.Tech', label: 'B.Tech' },
      //       { value: 'M.Tech', label: 'M.Tech' },
      //       { value: 'BCA', label: 'BCA' }
      //   ],
      //   isMulti: false
      // },
      {
        type: 'text',
        name: 'Education',
        label:  'Education',
        placeholder: 'आपले शिक्षण प्रविष्ट करा',
        section: 'Personal Details',
        registerProps: { required: 'Education is required' },
      },
      {
        type: 'select',
        name: 'Employed In',
        label: 'Employed In',
        registerProps: { required: 'Employed In is required' },
        section: 'Professional Details',
        options: [
          { value: 'Government', label: 'Government' },
          { value: 'Private', label: 'Private' },
          { value: 'Self - employed', label: 'Self - employed' },
          { value: 'Business', label: 'Business' },
          { value: 'Not Working', label: 'Not Working' },
        ],
        isMulti: false
      },
      {
        type: 'text',
        name: 'Organization',
        section: 'Professional Details',
        label: 'Organization',
        placeholder: 'तुमचा संघटनाचे नाव प्रविष्ट करा',
        registerProps: { required: 'Organization is required' }
      },
      {
        type: 'text',
        name: 'Annual Income',
        section: 'Professional Details',
        label: 'Candidate Annual Income (in Lakhs)',
        placeholder: 'तुमचा वार्षिक उत्पन्न प्रविष्ट करा',
        registerProps: { required: 'Annual Income is required' }
      },
      {
        type: 'text',
        name: 'Occupation',
        label: 'Occupation',
        section: 'Professional Details',
        placeholder: 'तुमचा व्यावसाय प्रविष्ट करा',
        registerProps: { required: 'Occupation is required' }
      },
      {
        type: 'text',
        name: 'Occupation City',
        label: 'Occupation City',
        section: 'Professional Details',
        placeholder: 'तुमचे कार्यरत शहर प्रविष्ट करा.',
        registerProps: { required: 'Enter Your Occupation City' }
      },
      {
        type: 'text',
        name: 'Guardian name',
        section: 'Family Details',
        label: 'Guardian name',
        placeholder: 'तुमच्या पालकांचे नाव प्रविष्ट करा',
        registerProps: { required: 'Guardian name is required' }
      },
      {
        type: 'text',
        name: 'Releation with Guardian',
        label: 'Releation with Guardian',
        section: 'Family Details',
        placeholder: 'पालकाशी संबंध',
        registerProps: { required: 'Releation with Guardian is required' }
      },

      {
        type: 'text',
        name: 'Guardian Mobile Number*',
        label:  'Guardian Mobile Number*',
        section: 'Family Details',
        placeholder: 'पालकाचा मोबाईल नंबर प्रविष्ट करा',
        registerProps: { required: 'Guardians Mobile Number is required' }
      },
      {
        type: 'text',
        name: 'Guardian Occupation',
        label: 'Guardian Occupation',
        section: 'Family Details',
        placeholder: 'तुमच्या पालकांचा व्यवसाय प्रविष्ट करा',
        registerProps: { required: 'Guardian Occupation is required' }
      },
      {
        type: 'text',
        name: 'Maternal uncle name',
        section: 'Family Details',
        label: 'Maternal uncle name',
        placeholder: 'तुमच्या मावसभावाचा नाव प्रविष्ट करा',
        registerProps: { required: 'Maternal uncle name is required' }
      },
      {
        type: 'text',
        name: 'Family Annual Income',
        section: 'Family Details',
        label: 'Family Annual Income (in Lakhs)',
        placeholder: 'तुमचा वार्षिक उत्पन्न प्रविष्ट करा',
        registerProps: { required: 'Family Annual Income is required' }
      },
      {
        type: 'number',
        name: 'Family Members',
        label:  'Family Members',
        section: 'Family Details',
        placeholder: 'तुमच्या कुटुंबातील सदस्यांची माहिती प्रविष्ट करा',
        // registerProps: { required: 'Guardians Occupation is required' }
      },
      {
        type: 'number',
        name: 'No. of Brothers*',
        label:  'No. of Brothers*',
        section: 'Family Details',
        placeholder: 'Enter your  No. of Brothers',
        // registerProps: { required: 'Guardians Occupation is required' }
      },
      {
        type: 'number',
        name: 'No. of sister*',
        label:  'No. of sister*',
        section: 'Family Details',
        placeholder: 'Enter your  No. of sister',
        // registerProps: { required: 'Guardians Occupation is required' }
      },
      {
        type: 'number',
        name: 'No. of Brothers married',
        label:  'No. of Brothers married',
        section: 'Family Details',
        placeholder: 'Enter your No. of Brothers married',
        // registerProps: { required: 'Guardians Occupation is required' }
      },
      {
        type: 'number',
        name: 'No. of Sisters married',
        label:  'No. of Sisters married',
        section: 'Family Details',
        placeholder: 'Enter your No. of Sisters married',
        // registerProps: { required: 'Guardians Occupation is required' }
      },
      {
        type: 'text',
        name: 'Address*',
        label:  'Address*',
        section: 'Location Details',
        placeholder: 'तुमचा पत्ता प्रविष्ट करा',
        registerProps: { required: 'Address is required' }
      },
      {
        type: 'text',
        name: 'Locality*',
        label: 'Locality*',
        section: 'Location Details',
        placeholder: 'तुमची स्थानिकता प्रविष्ट करा',
        registerProps: { required: 'Locality is required' }
      },
      {
        type: 'text',
        name: 'City*',
        label:  'City*',
        section: 'Location Details',
        placeholder: 'तुमचा शहर प्रविष्ट करा',
        registerProps: { required: 'City is required' }
      },
      {
        type: 'select',
        name: 'State',
        registerProps: { required: 'state is required' },
        section: 'Location Details',
        label: 'State',
        options:[
          { value: 'Maharashtra', label: 'Maharashtra' },
          { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
          { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
          { value: 'Assam', label: 'Assam' },
          { value: 'Bihar', label: 'Bihar' },
          { value: 'Chhattisgarh', label: 'Chhattisgarh' },
          { value: 'Chandigrah', label: 'Chandigrah' },
          { value: 'Goa', label: 'Goa' },
          { value: 'Gujrat', label: 'Gujrat' },
          { value: 'Haryana', label: 'Haryana' },
          { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
          { value: 'Jammu & Kashmir', label: 'Jammu & Kashmir' },
          { value: 'Jharkhand', label: 'Jharkhand' },
          { value: 'Delhi', label: 'Delhi' },
          { value: 'Karnataka', label: 'Karnataka' },
          { value: 'Kerala', label: 'Kerala' },
          { value: 'Ladakh', label: 'Ladakh' },
          { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
          { value: 'Manipur', label: 'Manipur' },
          { value: 'Meghalaya', label: 'Meghalaya' },
          { value: 'Mizoram', label: 'Mizoram' },
          { value: 'Nagaland', label: 'Nagaland' },
          { value: 'Odisha', label: 'Odisha' },
          { value: 'Pondicherry', label: 'Pondicherry' },
          { value: 'Punjab', label: 'Punjab' },
          { value: 'Rajasthan', label: 'Rajasthan' },
          { value: 'Sikkim', label: 'Sikkim' },
          { value: 'Telangana', label: 'Telangana' },
          { value: 'Tripura', label: 'Tripura' },
          { value: 'Uttarakhand', label: 'Uttarakhand' },
          { value: 'Utter Pradesh', label: 'Utter Pradesh' },
          { value: 'West Bengal', label: 'West Bengal' }
      ],
        isMulti: false
      },
  
      {
        type: 'text',
        name: 'Pincode*',
        label:  'Pincode*',
        section: 'Location Details',
        placeholder: 'Enter your  Pincode',
        registerProps: { required: 'Pincode is required' }
      },
     
      {
        type: 'text',
        name: 'Country*',
        label:  'Country*',
        section: 'Location Details',
        placeholder: 'India',
        // registerProps: { required: 'Pincode is required' }
      },
  ];
  
//  fields