import React, { ForwardedRef, RefObject, useEffect, useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { useFormContext, Controller, FieldValues, FieldError } from 'react-hook-form';
import { EColor } from '../../styles/Colors/Colors';

interface Option {
  value: string;
  label: string;
}

interface SelectField {
  type: 'select';
  label: string;
  name: string;
  options: Option[];
  isMulti?: boolean;
}

interface SelectInputProps {
  field: SelectField;
  value?: string;
  // onEnterPress?: () => void; 
  // inputRef: RefObject<SelectInstance<any, boolean>> | null; 
}

const SelectInput: React.FC<SelectInputProps> = ({ field, value}) => {
  const { control, formState: { errors }, clearErrors, setValue } = useFormContext<FieldValues>();
  const selectInputRef = useRef<SelectInstance<any, boolean>>(null);
  // Check if the field should be hidden when the value is 'female'
  const hideField = value?.toLowerCase() === 'female' && field.name === 'House';

  // Clear errors if the field is hidden (House field)
  useEffect(() => {
    if (hideField) {
      clearErrors(field.name);
      // Optionally set the value of House to 'NA' when hidden
      setValue(field.name, 'NA');
    }
  }, [hideField, clearErrors, setValue, field.name]);

  // Do not render the field if it should be hidden
  if (hideField) return null;

  // const handleKeyPress = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault(); 
  //     if (onEnterPress) {
  //       onEnterPress(); 
  //     }
  //   }
  // };
  
  // console.log('oooooo',inputRef)

  return (
    <div>
      <label htmlFor={field.name} style={{color:'black', marginBottom:4}}>{field.label}</label>
      <Controller
        name={field.name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
          isSearchable={false}
          openMenuOnFocus={true}
          // ref={ field.name === 'Gender' ? inputRef  : field.name === 'Physical Status' ? inputRef : field.name === 'Gender'? inputRef : undefined}
          // onKeyDown={handleKeyPress}
          closeMenuOnSelect
          styles={{
            control: (provided) => ({
              ...provided,
              marginBottom: 16,
              color: 'black',
              borderColor: EColor.BLACK,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
            }),
            menu: (provided) => ({
              ...provided,
              borderWidth: 1,
              color: 'black',
              borderColor: EColor.BLACK,
              borderRadius: 8,
            }),
          }}
          
          onChange={(selectedOption) => {
            // Check if the selected option is 'female' and field is 'House'
            if (value?.toLowerCase() === 'female' && field.name === 'House') {
              clearErrors(field.name);
              onChange('NA'); // Set House to 'NA' when 'female' is selected
            } else {
              // Handle normal onChange for other cases
              if (field.isMulti) {
                onChange(selectedOption.map((option: { value: any; }) => option.value));
              } else {
                onChange(selectedOption.value);
              }
            }
          }}
          value={field.isMulti
            ? (value || []).map((val: string) => field.options.find(option => option.value === val))
            : field.options.find(option => option.value === value)
          }
          options={field.options}
          isMulti={field.isMulti}
        />
        )}
      />
      
      {/* Display error unless field is hidden (e.g., for 'House' when 'female' is selected) */}
      {!(value?.toLowerCase() === 'female' && field.name === 'House') && errors[field.name] && (
        <p style={{ color: 'red' }}>{(errors[field.name] as FieldError)?.message}</p>
      )}
    </div>
  );
};

export default SelectInput;
