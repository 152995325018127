import React from 'react';
import './Footer.css';
import { EColor } from '../../styles/Colors/Colors';
import Instagram from '../../assets/Images/Instagram.png';
import Fb from '../../assets/Images/Facebook.png';
import Linkd from '../../assets/Images/LinkedIn.png';
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer-main text-white pt-4 mt-4" style={{backgroundColor: '#651a1a'}}>
      <div className="container">
        <div className='row' style={{marginBottom:0}}>
          <div className="col-md-6 text-center">
            <h5>Follow Us</h5>
            <a href="https://www.facebook.com/thetravobuddy" className="text-white me-3">
              <img src={Fb} alt="Facebook" style={{ width: '60px', height: '60px' }} />
            </a>
            <a href="https://www.instagram.com/travo.buddy" className="text-white me-3">
              <img src={Instagram} alt="Instagram" style={{ width: '60px', height: '60px' }} />
            </a>
            {/* <a href="#" className="text-white me-3">
              <img src={twitter} alt="Twitter" style={{ width: '60px', height: '60px' }} />
            </a> */}
            <a href="https://www.linkedin.com/company/vipracube-tech-solutions" className="text-white">
              <img src={Linkd} alt="LinkedIn" style={{ width: '60px', height: '60px' }} />
            </a>
          </div>
          <div className="col-md-6 text-center">
            <h5>About Us</h5>
            <p style={{width:'80%', color: EColor.WHITE}}><strong style={{ color: EColor.WHITE,fontSize:16 }}>Jain Utkarsh Mandal </strong> -"Social, Cultural and educational prosperity"  (since - 01/08/1997)</p>
          </div>
          
        <div className="row">
          </div><div className="termsAndPrivacyBlock">
            <Link target='blank' to="/TermsConditions" className='Termsconditions'><p style={{color:"white"}}>Terms And Condition</p></Link>
            <Link target='blank' to="/PrivacyPolicy" className='PrivacyPolicy'><p style={{color:"white"}}>Privacy Policy</p></Link>
          </div>
          <div className="col text-center">
            <p style={{color:"white"}} className="mb-0">&copy; 2024 Jain Utkarsh Mandal. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
