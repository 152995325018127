import React from 'react';
import { useController } from 'react-hook-form';
import './Numberinput.css'; // Import custom styles if needed

interface NumberInputProps {
  name: string;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onEnterPress?: () => void; 
}

const NumberInput: React.FC<NumberInputProps> = ({
  name,
  label,
  min = 0,
  max = 100,
  step = 1,
  placeholder,
  inputRef,
  onEnterPress,

}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController({ name });

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      if (onEnterPress) {
        onEnterPress(); 
      }
    }
  };

  return (
    <div className="number-input-container">
      <label htmlFor={name}>{label}</label>
      <div className="number-input-wrapper">
        <button
          type="button"
          className="number-input-button"
          onClick={() => onChange(Math.max(min, (value || 0) - step))}
        >
          -
        </button>
        <input
          ref={inputRef}
          id={name}
          onKeyDown={handleKeyPress} 
          type="number"
          value={value || 0}
          onChange={(e) => onChange(Number(e.target.value))}
          onBlur={onBlur}
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
        />
        <button
          type="button"
          className="number-input-button"
          onClick={() => onChange(Math.min(max, (value || 0) + step))}
        >
          +
        </button>
      </div>
      {error && <p className="error-message" style={{color:"red"}}>{error.message}</p>}
    </div>
  );
};

export default NumberInput;
