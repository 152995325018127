import React, { useEffect } from 'react';
import { useFormContext, Controller, FieldValues, FieldError } from 'react-hook-form';
import { EColor } from '../../styles/Colors/Colors';

interface TextField {
  type: 'text';
  label: string;
  name: string;
  placeholder?: string;
  registerProps?: any; // Adjust type as needed
  isMobileNumber?: boolean; // Prop to indicate if field is for mobile number
  value?: string;
}

interface TextInputProps {
  field: TextField;
  value?: string;
  isLand?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onEnterPress?: () => void; 
}

const TextInput: React.FC<TextInputProps> = ({ field, value, isLand, onEnterPress,inputRef }) => {
  const { control, setValue, formState: { errors }, clearErrors } = useFormContext<FieldValues>();

  console.log('field.name', field.name, isLand, errors,value);

  // Validation rules for mobile number if field.name is 'Mobile number'
  const validationRules = field.name === 'Mobile number'
    ? {
        required: 'Mobile number is required',
        pattern: {
          value: /^[0-9]{10}$/,
          message: 'Mobile number must be 10 digits'
        }
      }
    : {};

  const hideField = (value?.toLowerCase() === 'not working') && 
                    (field.name === 'Organization' || field.name === 'Annual Income' || field.name === 'Occupation' || field.name === 'Occupation City');
                    
  const hideFields = (isLand?.toLowerCase() === 'female') && 
                    (field.name === 'Agricultural Land');


  useEffect(() => {
    if (hideField) {
      clearErrors(field.name);
      setValue(field.name, 'NA');
    }
  }, [hideField, clearErrors, setValue, field.name]);

  if (hideField || hideFields) return null;
  

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      if (onEnterPress) {
        onEnterPress(); 
      }
    }
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: 12 }}>
      <label htmlFor={field.name} style={{ color: 'black', marginBottom: 4 }}>{field.label}</label>
      <Controller
        name={field.name}
        control={control}
        rules={validationRules}
        render={({ field: { onChange, value: fieldValue } }) => (
          <input
            disabled={field.name === 'Country*' || (fieldValue?.toLowerCase() === 'not working')}
            maxLength={
              ['Mobile number', 'Guardian Mobile Number*'].includes(field.name)
                ? 10
                : field.name.toLowerCase() === 'expectation'
                ? 30
                : 100
            }
            style={{
              borderWidth: 1,
              borderColor: EColor.BLACK,
              padding: 6,
              borderRadius: 8,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
            }}
            type="text"
            onChange={(e) => {
              const inputValue = e.target.value;
              // Check for "not working" and clear errors for Organization, Occupation, and Annual Income
              if (inputValue.toLowerCase() === 'not working') {
                onChange('NA');
                clearErrors(['Organization', 'Occupation', 'Annual Income','Occupation City']); // Clear errors for specific fields
              } else {
                onChange(inputValue);
              }
            }}
            placeholder={field.placeholder}
            value={fieldValue}
            ref={inputRef}
            onKeyDown={handleKeyPress} 
          />
        )}
      />
      {(value?.toLowerCase() === 'not working' || isLand?.toLowerCase() === 'female') && ['Organization', 'Occupation', 'Annual Income','Agricultural Land','Occupation City'].includes(field.name) ? null : (
        <p style={{ color: 'red' }}>{(errors[field.name] as FieldError)?.message}</p>
      )}
    </div>
  );
};

export default TextInput;
