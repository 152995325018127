import React from 'react';
import image6 from '../../assets/Images/IMG_1080.jpg';
import image7 from '../../assets/Images/img8.jpeg';
import './ImageCarouselStyle.css';


  

const ImageCarousel = () => {
  return (
    <div className="imageCarouselMain" >
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={image6}  style={{ borderRadius: '15px' ,width:"100%", height:"100%"}} alt="Carousel 1" />
          </div>
          <div className="carousel-item">
            <img src={image6}  style={{ borderRadius: '15px',width:"100%", height:"100%" }} alt="Carousel 2" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default ImageCarousel;
