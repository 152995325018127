import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb'; // Import UK locale for DD/MM/YYYY format
import './DateTime.css';

const DateTimeInput = ({ field }) => {
  const { control, formState: { errors } } = useFormContext();

  // Set moment locale globally to 'en-gb' for DD/MM/YYYY
  moment.locale('en-gb');

  // Define refs for DatePicker and TimePicker
  const datePickerRef = useRef(null);
  const timePickerRef = useRef(null);

  // Convert input value to Moment object or null
  const getSelectedDate = (value) => {
    if (value) {
      if (field.type.toLowerCase() === 'datetime') {
        const date = moment(value, 'DD/MM/YYYY');
        return date.isValid() ? date : null;
      } else if (field.type.toLowerCase() === 'time') {
        const time = moment(value, 'HH:mm');
        return time.isValid() ? time : null;
      }
    }
    return null;
  };

  // Use ref to programmatically focus DatePicker/TimePicker if needed
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      if (field.type.toLowerCase() === 'datetime' && datePickerRef.current) {
        datePickerRef.current.focus();
      } else if (field.type.toLowerCase() === 'time' && timePickerRef.current) {
        timePickerRef.current.focus();
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="en-gb">
      <div style={{ display: 'flex', flexDirection: 'column' }} onKeyPress={handleEnterPress}>
        <label style={{ color: 'black', marginBottom: 4 }}>{field.label}</label>
        <Controller
          name={field.name}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            const selectedDate = getSelectedDate(value);

            return (
              <>
                {field.type.toLowerCase() === 'datetime' && (
                  <DatePicker
                    ref={datePickerRef}
                    value={selectedDate}
                    onChange={(date) => {
                      const formattedDate = date ? date.format('DD/MM/YYYY') : null;
                      onChange(formattedDate);
                    }}
                    className="custom-date-picker"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="custom-date-picker"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'DD/MM/YYYY',
                        }}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    mask="__/__/____"
                    disableFuture
                  />
                )}
                {field.type.toLowerCase() === 'time' && (
                  <TimePicker
                    ref={timePickerRef}
                    value={selectedDate}
                    ampm={true}
                    minutesStep={1} // Ensure 1-minute increments
                    onChange={(date) => {
                      const formattedTime = date ? moment(date).format('HH:mm A') : null;
                      onChange(formattedTime);
                    }}
                    className="custom-time-picker"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: '08:00 AM', 
                        }}
                        className="custom-time-picker"
                      />
                    )}
                    inputFormat={'hh:mm A'} 
                  />
                )}
              </>
            );
          }}
        />
        {errors[field.name] && <p style={{ color: 'red' }}>{errors[field.name]?.message}</p>}
      </div>
    </LocalizationProvider>
  );
};

export default DateTimeInput;
