 import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';



import {ECardsDetails, ICardState, IUserApiResponse} from './getCards.models'
import { APIKEYS } from '../../../utils/Keys';


const userInitialState: ICardState = {
    CardLoading: false,
    CardSuccess: null,
    CardError: '',
};


export const getCardsData = createAsyncThunk(ECardsDetails.GET_CARDS_DETAILS, async (data: string) => {

    try {
        const tokanData = {
            username:"jainmatrimonyapi",
            password:"jainmatrimonyapi"
         }
         const Tokan = await axios.post(`${APIKEYS.API}verify`, tokanData)
         const t = Tokan.data.token;
        if (t) {
            const headers = {
                Authorization: `Bearer ${t}`,
                'Content-Type': 'application/json',
            };
            
           
          
            const Api = `${APIKEYS.API}customers?pageSize=50&pageNumber=${data}`;
            
            console.log(`api calls`,Api)
            const response = await axios.get<IUserApiResponse>(Api, {headers: headers});
            console.log('response of cards',response.data.data)
            if(response) {
               console.log('response',response.data.data)
                return response.data;
            }
        }
    } catch (e) {
        console.error('Error fetching cards', e);
        throw e;
    }
});

const getCardsDataSlice = createSlice({
    name: ECardsDetails.GET_CARDS_DETAILS,
    initialState: userInitialState,
    reducers: {
        resetData: (state) => {
            state.CardSuccess = null;
            state.CardError = null;
            state.CardLoading = false;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getCardsData.pending, (state) => {
                state.CardLoading = true;
            })
            .addCase(getCardsData.fulfilled, (state, action) => {
                state.CardLoading = false;
                if (action.payload) {
                    state.CardSuccess = action.payload;
                }
                state.CardError = null;
            })
           .addCase(getCardsData.rejected, (state) =>{
            state.CardError = 'something went wrong';
            state.CardLoading = false;
           });
    },
});

export const { resetData } = getCardsDataSlice.actions;
export default getCardsDataSlice.reducer;