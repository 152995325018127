import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Carasoul.css';
import { MockData } from '../Cards/Cards.models';
import Indian from '../../assets/Images/indian.jpg'

interface CarouselProps {
  data: MockData;
  index: number;
  isDownload?: boolean;
}

function Carasoul({ data, index, isDownload }: CarouselProps) {
  return (
    <div className="card" style={{marginLeft:10}}>
      {/* <img src={Indian} className="card-img-top" alt={data.title} /> */}
      <div className="card-body">
        <p className="card-text">
          {data.content}
        </p>
      </div>
    </div>
  );
}

export default Carasoul;
