import React, { useEffect, useState } from 'react';
import "./CustomerTableStyle.css";
import { Link, useNavigate } from 'react-router-dom';
import arrowBack from "../../../assets/Images/arrow_back.png";
import { EColor } from '../../../styles/Colors/Colors';
import { useAppDispatch, useAppSelector } from '../../../states/store';
import { getCardsData } from '../../../states/Slice/GetCards/getCards.slice';
import { Data } from '../../../states/Slice/GetCards/getCards.models';
import moment from 'moment';
import axios from 'axios';
import { APIKEYS } from '../../../utils/Keys';

const CustomerTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const [page, setPage] = useState(0); // Internal page state
  const [totalPages, setTotalPages] = useState(10);
  const [data, setData] = useState<Data>();
  const [hasNextPage, setHasNextPage] = useState(true);

  const reducer = useAppSelector((state) => state.getCardsSlice);

  // Fetch token for both view and download functions
  const fetchToken = async () => {
    const tokenData = {
      username: "jainmatrimonyapi",
      password: "jainmatrimonyapi"
    };
    const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
    return tokenResponse.data.token;
  };

  const viewPDF = async () => {
    try {
      const token = await fetchToken();
  
      const response = await fetch(`${APIKEYS.API}customers/pdf`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Open the PDF in a new tab for viewing
      window.open(url, '_blank');
  
    } catch (error) {
      console.error('Error viewing PDF:', error);
    }
  };

  const downloadPDF = async () => {
    try {
      const token = await fetchToken();
  
      const response = await fetch(`${APIKEYS.API}customers/pdf`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link element for downloading the PDF
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'registration_list.pdf'); // Set the file name
      document.body.appendChild(link);
  
      // Simulate a click to automatically download the PDF
      link.click();
  
      // Cleanup: remove the link and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const d = { 'page': page };
      await dispatch(getCardsData(d.page.toString()));
    };
    fetchData();
  }, [dispatch, page]);

  useEffect(() => {
    if (reducer.CardSuccess?.data) {
      setData(reducer.CardSuccess.data);
      setTotalPages(reducer.CardSuccess.data.totalPages);

      // Check if there is data for the next page
      setHasNextPage(reducer.CardSuccess.data.content.length > 0 || page < totalPages - 1);
    }
  }, [reducer, page, totalPages]);

  useEffect(() => {
    const userId = localStorage.getItem("userKey");
    if (userId !== "asWqFreafdgADFGRE@#%34563") {
      navigate("/LogIn");
    }

    // Handle browser back navigation
    const handlePopState = () => {
      localStorage.removeItem("userKey");
      navigate("/LogIn");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage - 1); 
    }
  };

  const LogOut = () => {
    localStorage.removeItem("userKey");
    navigate("/LogInUser");
  };

  return (
    <div className='customerTable'>
      <button onClick={(): void => navigate('/Register')} style={{ backgroundColor: EColor.BROWN, display: "flex", justifyContent: 'flex-end', alignItems: "center", paddingTop: 12, color: 'white', borderRadius: 8, borderColor: "white", overflow: "hidden" }}>
        <p style={{ color: "white", fontSize: 12 }}>Registration form for admin</p>
      </button>

      <div className='customerTableTitle'>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Link to="/AdminPanel" className='backArrow'>
            <img className='arrow' src={arrowBack} alt="Back" />
          </Link>
        </div>
        <h2>Register List</h2>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", columnGap: 12 }}>
          <div className='customerCountCardsTotal'>
            <div className='customerType' style={{ color: EColor.BROWN }}>Total Customers</div>
            <div className='customerCount' style={{ color: EColor.BROWN }}>{reducer.CardSuccess?.data.totalElements}</div>
          </div>

          {/* View PDF Button */}
          <button onClick={viewPDF} className="pay-button">View Registration List</button>

          {/* Download PDF Button */}
          <button onClick={downloadPDF} className="pay-button">Download Registration List</button>

          <div className='logOutButton' onClick={LogOut} style={{ color: EColor.WHITE, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <button style={{ width: 100, backgroundColor: '#ff3333', textAlign: "center", padding: 6, borderRadius: 8, borderColor: 'red', color: "wheat" }}>Log Out</button>
          </div>
        </div>
      </div>

      {/* Table and Pagination logic */}
      <div className='customerDataTable'>
        {/* Table code remains the same */}
        <table>
          <thead style={{ backgroundColor: EColor.WHITE, color: EColor.BROWN }}>
            <tr>
              <th>SrNo</th>
              <th>Registration Number</th>
              <th>Registration Date</th>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Amount Paid</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.content ? 
            data?.content?.map((customer: any, index: number) => (
              <tr key={customer.id} style={{ color: 'black' }}>
                <td>{index + 1}</td>
                <td>{customer.registrationNo}</td>
                <td>{moment(customer.createdOn).format('DD/MM/YYYY')}</td>
                <td>{customer.firstName} {customer?.lastName}</td>
                <td>{customer.primaryMobNumber}</td>
                <td>1200 -/</td>
                <td>
                  <button className='customerDataTableDoneButton'>Paid</button>
                </td>
              </tr>
            )) : <h2>No data Found</h2>}
          </tbody>
        </table>
      </div>

      {/* Pagination buttons */}
      <div className='pagination'>
        <button 
          className='pagination-btn' 
          onClick={() => handlePageChange(page)} 
          disabled={page === 0}
        >
          Previous
        </button>

        <div className='pagination-numbers'>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <button 
              key={pageNumber} 
              className={`pagination-number ${pageNumber === page + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
        </div>

        <button 
          className='pagination-btn' 
          onClick={() => handlePageChange(page + 2)} 
          disabled={!hasNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerTable;
