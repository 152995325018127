export enum EPhonePay {
    PHONEPAY = 'PHONEPAY'
}


export interface IPaymentData {
    paymentId: number;
    transactionId: string;
    amount: number;
    mobileNumber: string;
    status: string;
    merchantTransactionId: string | null;
    paymentType: string | null;
    url: string;
    utr: string | null;
    paymentInstrument: string | null;
    merchantId: string | null;
    createdOn: string;
    updatedOn: string;
}


export interface INum {
    number:string,
    amount:string
}


export interface IPhonepayApiResponse {
    data: IPaymentData;
    status: string;
    message: string;
    responsecode: number;
}

export interface IPhone {
   PhonepaySuccess: IPhonepayApiResponse | null;
   PhonepayLoading: boolean;
   PhonepayError: string | null;
}