


export enum EColor {
      BROWN = '#d55d5d',
    GREEN = '#33cc33',
    DARKBLUE =  '#000066',
        BACKGROUND = '#FFA502',
        BUTTON_COLOR = '#e6ccff',
        LIGHT_ORANGE = '#F39F6F',
        OFF_WHITE = '#FEF9EF',
        GERY = '#E9EDEA',
        BLACK = '#171615',
        ORANGE = '#FE9526',
        WHITE = '#FFFFFF',
        RED = '#FF0000',
        PINK = '#e6ccff',
        DISABLEPINK = '#fde8f0',
        TEXTPINK = '#b366ff',
        LIGHTPINK = '#f2e6ff',
        DARKPINK = '#f273a6',
        LIGHTVIOLET = '#bf80ff',
      EXTRALIGHTVIOLET = '#d9b3ff',
      LIGHTGREEN = "#d9b3ff"   
}