import React, { useEffect, useState } from 'react';
import "./LogInStyle.css";
import { useNavigate } from 'react-router-dom';
import loginData from './LogInAccess/LogInAccess';
import { ReusableFormProps } from '../../components/Form/ReusableModels';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import ReusableForm from '../../components/Form/ReusableForm.component';
import { fields } from '../../components/Form/ReusableForm';
import { EColor } from '../../styles/Colors/Colors';
import { useAppDispatch,useAppSelector } from '../../states/store';
import { getEducData } from '../../states/Slice/Education/Edu.slice';
import { IImageInfo, IUserData } from '../../states/Slice/registerUser/register.models';
import { getStateData } from '../../states/Slice/State/State.slice';
import { postCustomerRegistration } from '../../states/Slice/registerUser/register.slice';
import { toast } from 'react-toastify';
import TextInput from '../../components/TextInput/TextInput.component';
import ReadOnlyForm from './ReadOnly';
import profilePic from '../../assets/Images/profilepicdefault.png'



export interface FormData {
  'Mobile number': string;
  Firstname: string;
  Lastname: string;
  Gender: string;
  'Date Of Birth': string;
  'Birth Place'?: string;
  'Family Annual Income'?:string
  'Time'?: string;
  'Blood Group': string;
  'Relation With Guardian'?: string;
  'Maritial Status': string;
  'Mother Toungue': string;
  'Expectation':string;
  'Releation with Guardian': string;
  Height: string;
  'Agricultural Land': string ;
  'Guardian Mobile Number*': string | null;
  'Middle name': string;
  Weight?: string;
  Hobbies?: string;
  Colour?: string;
  Spec: string;
  'Physical Status': string;
  'House'?: string;
  'Guardian name': string;
  'Guardian Occupation': string;
  'Family Members': number;
  'No': {
    ' of Brothers*': number;
    ' of sister*': number;
    ' of Brothers married': number;
    ' of Sisters married': number;
  };
  'Education'?: string;
  'Employed In'?: string;
  'Occupation'?: string;
  'Organization'?: string;
  'Annual Income'?: string;
  'Cast'?: string;
  'Sub-cast'?: string;
  'Maternal uncle name'?: string;
  "Occupation City"?: string;
  'User Name': string;
  Password: string;
  'Address*': string;
  'City*': string;
  'Pincode*': string;
  State?: string;
}

const LogIn = <T extends FieldValues> () => {
  const methods = useForm<T>();
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch()
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const reducer = useAppSelector((state)=>state.EduSlice)
  const reducerState = useAppSelector((state)=>state.StateSlice)
  const [data,setData]=useState<IUserData>()

  // Additional state variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<IImageInfo>();
  // New state for storing form data
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitForm,showSubmitform] = useState(false);


  useEffect(()=>{
    dispatch(getEducData())
    dispatch(getStateData())
  },[dispatch])



  console.log('sssssssss',reducer,reducerState)

  const handleSubmit = () => {
    // Simple validation
    if (username === loginData.userName && password === loginData.password) {
      if (firstName && lastName && email && address) {
        localStorage.setItem("userKey", "asWqFreafdgADFGRE@#%34563");
        navigate("/AdminPanel");
      } else {
        setEmptyError(true);
        setAlert(false);
      }
    } else if (username.length === 0 || password.length === 0) {
      setEmptyError(true);
      setAlert(false);
    } else {
      setEmptyError(false);
      setAlert(true);
    }
  };


 
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log('Selected file:', file);

    if (file) {
        // Set the selected image file in state
        setSelectedImage(file);

        // Create a URL for the selected file
        const fileUrl = URL.createObjectURL(file);

        // Set the preview URL in state with the selected image
        const imag: IImageInfo = {
            uri: fileUrl,
            name: file.name,
            type: file.type,
        };
        setPreviewUrl(imag);
        
        // Optional: Clean up the URL object when it's no longer needed
        // URL.revokeObjectURL(fileUrl); // Uncomment this if you want to manually clean up
    } 
};


  console.log('reeee',previewUrl)


  


  const onSubmit: SubmitHandler<FormData> = (data) => {
    const dataToSend: IUserData = {
      primaryMobNumber: data['Mobile number'] || '',
      firstName: data['Firstname'] || '',
      lastName: data['Lastname'] || '',
      gender: data['Gender'] || '',
      dateOfBirth: data['Date Of Birth'] ,
      birthPlace: data['Birth Place'] || '',
      birthTime: data['Time'] ,
      bloodGroup: data['Blood Group'] || '',
      relWithGuardian: data['Relation With Guardian'] || '',
      maritalStatus: data['Maritial Status'] || '',
      motherTongue: data['Mother Toungue'] || '',
      height: data['Height'] || '',
      weight: data['Weight'] || '',  
      hobby: data['Hobbies'] || '',
      middleName: data['Middle name'] || '',
      File :selectedImage,
      colourOfBody: data['Colour'] || '',
      spec: data['Spec'].toLowerCase() === 'yes' ? true : false ,
      physicalStatus: data['Physical Status'] || '',
      mobileNo: data['Mobile number'] || '',
      status: 'A',
      profilePic: null,  
      educationDetails: data['Education'] || '',
      employedIn: data['Employed In'] || '',
      occupation: data['Occupation'] || '',
      organization: data['Organization'] || '',
      annualIncome: data['Annual Income'] || '',
      caste: data['Cast'] || '',
      uri:previewUrl?.uri,
      name:previewUrl?.name,
      type:previewUrl?.type,
      subcaste: data['Sub-cast'] || '',
      familyDetails: {
        fathersName: data['Guardian name'] || '',
        fathersOccupation: data['Guardian Occupation'] || '',
        familyMembers: data['Family Members'] || 0,
        numberOfBrothers: data['No'][' of Brothers*'] || 0,
        numberOfSisters: data['No'][' of sister*'] || 0,
        brothersMarried: data['No'][' of Brothers married'] || 0,
        sistersMarried: data['No'][' of Sisters married'] || 0,
        houseOwnership: data['House'] || '',
        fathersMobileNo:data['Guardian Mobile Number*'] || '',
        maternalUnclesName: data['Maternal uncle name'] || '',
      },
      customerCred: {
        userName: data['Mobile number'] || '',
        password: data['Mobile number'] || '',
      },
      addressDetails: [{
        addr: data['Address*']|| '',
        city: data['City*'] || '',
        pincode: data['Pincode*'] || '',
        state: data['State'] || '',
      }]
    };
  
    setData(dataToSend)
    // showSubmitform(true)
  //  dispatch(postCustomerRegistration(dataToSend)).then((res)=>{
  //   if(res.payload){
  //     toast.success('Registered Successfully in Jain Utkarsh Mandal ', {
  //       position:'top-right'
  //     });
  //     navigate("/QRCodePage")
  //   }
  //  })
  };





  return (
    <div 
    className='loginContainer'
    style={{
      backgroundColor: EColor.WHITE,
    }}
    >
     
      <div >
       
            <ReusableForm isAdmin={false} imgfile={selectedImage} img={previewUrl?.uri}  data-aos="flip-up" fields={fields} onupload={(e): void=>handleImageChange(e)} onSubmit={onSubmit} onclick={function (): void {
              throw new Error('Function not implemented.');
            } } />
      </div>
      
  </div>
  );
};

export default LogIn;
