import React from "react";
import Contactus from "../../assets/Images/contactus1.jpg";
import { EColor } from "../../styles/Colors/Colors"; 
import './ContactUs.css'

const ContactPage = (): React.ReactElement => {

      return (
        <div className="container" style={{ marginTop: '2vw',zIndex:2 }}>
          <div className="main">
            <div className="col-lg-6 order-lg-2" data-aos="zoom-in">
              <img src={Contactus} className="img-fluid image"  style={{ borderRadius: '20px' }} alt="Contact Us" />
            </div>
            <div className="col-lg-4 order-lg-1" data-aos="zoom-in" style={{ backdropFilter: 'blur(10px)', padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '20px', marginTop: '20px' }}>
              <h2 style={{ color: EColor.BROWN }}>Find Your Perfect Match with Jain Utkarsh Mandal </h2>
              <p  className="fonts" style={{ color: EColor.BLACK ,}}>
              Jain Utkarsh Mandal  makes finding your ideal life partner straightforward and meaningful. Connect with individuals who share your values and aspirations, all while embracing sustainable practices. Start your journey to a lasting relationship today!</p>
              <div style={{ marginTop: '2rem', color: EColor.BLACK }}>
                <h3 style={{ color: EColor.BROWN ,}}> Contact Information</h3>
                <ul className="fonts" style={{ listStyleType: 'none', padding: 0 }}>
                <strong>Address:</strong> 
  <li style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ marginLeft: '0px' }}>
    
   <p style={{marginBottom:0}}><strong style={{color:EColor.BROWN}}>Jain Utkarsh Mandal </strong> </p> 
   
   Dignmbar Jain Boarding, Near Modern Highschool, Kolhapur Road, Ichalkaranji, Tal. - Hathkangale, Dist.- Kolhapur
    </span>
  </li>
  <li style={{ marginTop: '8px' }}>
    <strong>Phone:</strong> 0230-2427100 / 08380051108
  </li>
  <li style={{ marginTop: '8px' }}>
    <strong>Email:</strong> utkarshvivah@gmail.com
  </li>
</ul>

              </div>
            </div>
          </div>
        </div>
      );
    };


export default ContactPage;
