import React from "react"; 
import AboutUsImage from '../../assets/Images/aboutUS.jpg';
import { EColor } from "../../styles/Colors/Colors";
import AboutUsCard from "../../components/AboutUsCard/AboutUsCard.component";

import './AboutUs.css';

const AboutUs = (): React.JSX.Element => {
  return (
    <div className="main-container">
      <div className="containers">
        <div className="text" data-aos="zoom-in"  style={{marginTop:24}}>
          <h2 style={{ color: EColor.BROWN, textAlign: "center" }}>About Jain Utkarsh Mandal </h2>
          <p className="pText" style={{ color: EColor.BLACK }}>
            Building on our 30-year legacy of trust, expertise, and success, <strong style={{color:EColor.BROWN,textDecorationLine:'underline'}}>Jain Utkarsh Mandal </strong> aims to revolutionize the matrimony experience for the global Jain community. We envision a future where our platform is the gold standard for Jain matchmaking, harnessing technology, tradition, and our unparalleled understanding of Jain values to:
          </p>
          <ul style={{ color: EColor.BLACK }}>
            <li>Create a seamless, enjoyable, and secure experience for our members</li>
            <li>Foster meaningful connections, leading to countless happy marriages and stronger family bonds</li>
            <li>Continuously innovate and adapt to the evolving needs of our community</li>
            <li>Remain the most trusted and revered matrimony platform, synonymous with excellence, integrity, and success</li>
          </ul>
        </div>
        <div data-aos="zoom-in">
          <img src={AboutUsImage} className="img" alt="About Us" />
        </div>
      </div>

      <div className="containers">
      <div data-aos="zoom-in">
          <img src={AboutUsImage} className="img" alt="Our Mission" />
        </div>
        <div className="text" data-aos="zoom-in" style={{marginTop:24}}>
          <h2 style={{ color: EColor.DARKBLUE, textAlign: "center" }}>Our Mission</h2>
          <p className="pText" style={{ color: EColor.DARKBLUE }}>
            Our mission is to empower individuals within the Jain community to discover their ideal life partner, fostering meaningful relationships built on trust, mutual respect, and shared values. We strive to create a sacred space for connections, guided by the principles of:
          </p>
          <ul style={{ color: EColor.DARKBLUE }}>
            <li>Ahimsa (Non-violence): Promoting compassion and kindness in every interaction</li>
            <li>Anekantavada (Non-absolutism): Embracing diversity and individuality</li>
            <li>Aparigraha (Non-possessiveness): Encouraging healthy and respectful relationships</li>
          </ul>
          <p style={{ color: EColor.DARKBLUE }}>
            Through innovative technology, personalized matchmaking, and expert guidance, we aim to make the journey of finding a life partner a joyful, seamless, and enriching experience, ultimately strengthening the bonds of our community.
          </p>
        </div>  
      </div>
    </div>
  );
}

export default AboutUs;
