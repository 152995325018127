import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import { ERegister, IImageInfo, IRegisterPersonalApiResponse, IRegisterState, IUserData } from "./adminRegister.models";
import { toast } from "react-toastify";
import ProfilePic from "../../../assets/Images/profilepicdefault.png";

// Initial state
const userInitialState: IRegisterState = {
  registerLoading: false,
  registerSuccess: null,
  registerError: "",
};

// Function to convert Base64 URI to Blob
const base64ToBlob = (base64: string, contentType = "image/jpeg") => {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
};

// Function to resize and convert an uploaded file to Base64
const resizeImage = (file: File, quality: number): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300, // Width
      300, // Height
      "JPEG",
      quality,
      0, // Rotation
      (uri) => resolve(uri as string), // Resized image in Base64
      "base64"
    );
  });

// AsyncThunk for registration
export const postAdminCustomerRegistration = createAsyncThunk(ERegister.REGISTER, async (data: IUserData) => {
  try {
    // Get token for authorization
    const tokenData = { username: "jainmatrimonyapi", password: "jainmatrimonyapi" };
    const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
    const token = tokenResponse.data.token;

    if (token) {
      const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
      const headersFormData = { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" };

      // Prepare registration data
      const sendData: IUserData = {
        ...data,
        birthTime: moment(data.birthTime, "HH:mm").format("hh:mm A"),
        profilePic: null,
        status: "A",
      };

      // Register the user
      const response = await axios.post<IRegisterPersonalApiResponse>(`${APIKEYS.API}customerweb`, sendData, { headers });

      // Upload profile picture if registration was successful
      if (response && response.data && response.data.data) {
        const formData = new FormData();
        let imageBlob: Blob;

        if (data.File) {
          // Resize and convert uploaded image
          const resizedImage = await resizeImage(data.File, 100);
          imageBlob = base64ToBlob(resizedImage);
        } else {
          // Fetch and convert default profile image
          const defaultResponse = await fetch(ProfilePic);
          imageBlob = await defaultResponse.blob();
        }

        // Append the Blob to FormData
        formData.append("file", imageBlob, data.File?.name || "default-image.jpg");

        // Upload profile picture
        const custId = response.data.data.custId;
        await axios.post(`${APIKEYS.API}profile/${Number(custId)}`, formData, { headers: headersFormData });

        toast.success("Registered Successfully in Jain Utkarsh Mandal", { position: "top-right" });
        return response.data;
      }
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
});

// Slice for registration
const postAdminCustomerRegistrationSlice = createSlice({
  name: ERegister.REGISTER,
  initialState: userInitialState,
  reducers: {
    resetdata: (state) => {
      state.registerSuccess = null;
      state.registerError = "";
      state.registerLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAdminCustomerRegistration.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(postAdminCustomerRegistration.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload || null;
        state.registerError = null;
      })
      .addCase(postAdminCustomerRegistration.rejected, (state) => {
        state.registerError = "Something Went Wrong";
        state.registerLoading = false;
      });
  },
});

export const { resetdata } = postAdminCustomerRegistrationSlice.actions;
export default postAdminCustomerRegistrationSlice.reducer;
