
import React from 'react';
import ImageCarousel from '../../components/GalleryCarasoul/ImageCarousel.component';
import ImageCarousel2 from '../../components/GalleryCarasoul/ImageCarousel2.component';


const Gallery = () => {

    return (
        <div className="container" style={{ marginTop: '2rem' ,padding:24 ,zIndex:2}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
               <ImageCarousel2/>
            </div>
        </div>
    );
}

export default Gallery;
