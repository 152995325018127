

export enum ERegister {
    REGISTER = 'REGISTER',
    PLACE_DOB = "PLACE_DOB",
    READYTOMARRY = "READYTOMARRY",
    SELECT = "SELECT",
}
interface IPersonalDetails {
    firstName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    dateOfBirth?: string | null;
    birthTime?: string | null;
    birthPlace?: string | null;
    maritalStatus?: string | null;
    motherTongue?: string | null;
    height?: string | null;
    weight?: string | null;
    colourOfBody?: string | null;
    physicalStatus?: string | null;
    mobileNo?: string | null;
    spec?: boolean;
    hobby?:string;
    bloodGroup?: string;
    ownHouse?: string | null;
    guardiansFullname?: string | null;
    relWithGuardian?: string | null ;
    readyToMarryWithinSubcast?: string | null,
    occOfGuardian?: string | null;
    UncleName?: string | null;
    hobbies?: string | null ;
    totalFamily?: string | null;
    noBrothers?: string | null;
    noSisters?: string | null;
    noMarreiedBrothers?: string | null;
    noMarreiedSisters?: string | null;
}

interface IProfessionalDetails {
    educationDetails?: string | null;
    employedIn?: string | null;
    occupation?: string | null;
    organization?: string | null;
    annualIncome?: string | null;
}

export interface IUserCred {
    userName: string;
    password: string;
}



export interface IUSERDoc {
    docId: string,
        profilePicUrl: string | null,
        bucketName: string | null,
        fileName: string | null,
}

export interface IReligiouesData {
    reli_Id: number;
    caste: string;
    subcaste: string;
}

export interface IFamilyData {
    family_Id?: number;
    fathersName?: string;
    familyAnnualIncome?:string |null
    mothersName?: string;
    fathersOccupation?: string;
    mothersOccupation?: string;
    familyMembers?: number;
    fathersMobileNo?:string | null;
    numberOfBrothers?: number;
    numberOfSisters?: number;
    brothersMarried?: number;
    sistersMarried?: number;
    houseOwnership?: string;
    maternalUnclesName?: string;
}

export interface IAddressDatas {
    country?: string;
    state?: string;
    city?: string;
    addr?: string;
    locality?:string;
    pincode?:string
}


export interface IUserData {
    custId?:string | null;
    occupationCity?:string |null;
    firstName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    agriculturalLand?:string | null;
    emailId?:string | null;
    dateOfBirth?: string | null;
    birthTime?: string | null;
    middleName?:string | null;
    birthPlace?: string | null;
    maritalStatus?: string | null;
    motherTongue?: string | null;
    height?: string | null;
    relationWithGuardian?: string | null;
    expectation?: string | null;
    weight?: string | null;
    colourOfBody?: string | null;
    physicalStatus?: string | null;
    mobileNo?: string | null;
    spec?: boolean;
    hobby?:string;
    addr?: string;
    locality?:string;
    pincode?:string;
    educationDetails?: string | null;
    employedIn?: string | null;
    occupation?: string | null;
    organization?: string | null;
    annualIncome?: string | null;
    bloodGroup?: string;
    ownHouse?: string | null;
    caste?:string |null;
    subcaste?:string | null;
    guardiansFullname?: string | null;
    relWithGuardian?: string | null ;
    readyToMarryWithinSubcast?: string | null,
    occOfGuardian?: string | null;
    UncleName?: string | null;
    hobbies?: string | null ;
    totalFamily?: string | null;
    File?:File|null ;
    fathersMobileNo?:string | null;
    noBrothers?: string | null;
    noSisters?: string | null;
    noMarreiedBrothers?: string | null;
    noMarreiedSisters?: string | null;
    primaryMobNumber?: string | null;
    familyDetails?: IFamilyData;
    addressDetails?: IAddressDatas[];
    profilePic: IUSERDoc | null;
    customerCred: IUserCred;
    status?: string;
    uri?:string,
      name?: string,
      type?: string,
}


export interface IImageInfo {
    name?: string;
    fileName?: string;
    fileSize?: number;
    height?: number;
    originalPath?: string;
    type?: string;
    uri?: string;
    width?: number;
    base64?: string;
    custId?:string;
  }

export interface IRegisterPersonalApiResponse {
    data: IUserData;
    status: string;
    message: string;
    responseCode: number;
}

export interface IRegisterState {
    registerLoading: boolean;
    registerSuccess: IRegisterPersonalApiResponse | null;
    registerError: string | null;
}